import React, { useEffect, useState, useRef } from "react";
import SideBar from "../components/sidebar";
import Home from "../components/pages/home.jsx";
import Members from "../components/pages/members/members.jsx";
import ExitedMembers from "../components/pages/members/exited";
import MemberDetails from "../components/pages/members/details";
import ExitedMemberDetails from "../components/pages/members/exited-details";
import Giving from "../components/pages/giving/giving.jsx";
import GivingDetails from "../components/pages/giving/givingDetails";
import GivingTypes from "../components/pages/giving/givingTypes";
import Tickets from "../components/pages/tickets/tickets";
import TicketDetails from "../components/pages/tickets/ticketDetails";
import AddGiving from "../components/pages/giving/addGiving";
import Groups from "../components/pages/group/groups.jsx";
import GroupDetails from "../components/pages/group/details.jsx";
import Report from "../components/pages/report/report.jsx";
import Settings from "../components/pages/settings/settings.jsx";
import Claims from "../components/pages/claims/claims.jsx";
import profile2 from "../images/icons/profile.jpeg";
import orgdrop from "../images/icons/orgdropdown.svg";
import notification from "../images/icons/notification.svg";
import Login from "../components/pages/auth/login";
import Account from "../components/pages/account/account";
import settings from "../components/getrequest";
import axios from "axios";
import { Link } from "react-router-dom";
import { ThreeCircles } from  'react-loader-spinner' 


function Header() {
  console.log(sessionStorage.getItem('access_token'))
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [notifications, setNotifications] = useState();
  const [singlenotifications, setSingleNotifications] = useState();
  const [notify, setNotify] = useState([]);
  function checkToken() {
    axios
      .get(urlbase + "/api/check-session", settings())
      .then((res) => {
        if (res.data.message == "Unauthenticated." || res.data.code == 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }
        if(res.data.data.due == 1 || res.data.data.sub == 0){
          if(!sessionStorage.getItem('chgamsSubscriptionDue')){
            if(res.data.data.due == 1){
              sessionStorage.setItem('chgamsSubscriptionDue',1)
            }
          }
          if(sessionStorage.getItem('chgamsSubscription') != 0){
            if(res.data.data.sub == 0){
              sessionStorage.setItem('chgamsSubscription',0)
            }
          }
          if(!sessionStorage.getItem('chgamsSubscriptionDue') && sessionStorage.getItem('chgamsSubscription') != 0){            
            window.location.reload()
          }
        }
      })
      .catch((err) => {
        sessionStorage.clear();
        window.location.href = "/login";
      });
  }
  const[processing,setProcessing] = useState()
  const switchOrg = (id) => {
      setOpenOrgs(0)
      setProcessing(1)
      axios.get(process.env.REACT_APP_BASE+'/api/org/switch/'+id,settings())
      .then((res) => {
          if(res.data.code == 200){
              const d = new Date();
              let time = d.getTime(); 
              sessionStorage.setItem('login',time)
              sessionStorage.setItem('prefix',res.data.data.user.prefix)
              sessionStorage.setItem('access_token',res.data.data.access_token)
              sessionStorage.setItem('user_name',res.data.data.user.user_name)
              sessionStorage.setItem('user_first_name',res.data.data.user.first_name)
              sessionStorage.setItem('user_last_name',res.data.data.user.last_name)
              sessionStorage.setItem('user_email',res.data.data.user.email)
              sessionStorage.setItem('enterprise',res.data.data.user.enterprise)
              sessionStorage.setItem('role',res.data.data.user.role)
              sessionStorage.setItem('chgamsEntImage',res.data.data.user.image)
              sessionStorage.setItem('chgamsSubscription',res.data.data.user.sub)
              sessionStorage.setItem('chgamsDashUrl',res.data.data.user.login)
              if(res.data.data.user.role == 4){
                sessionStorage.setItem('member_id',res.data.data.user.member_id)
              }
              window.location.href=res.data.data.user.login
          }else{
            alert(res.data.message)
          }
          setProcessing(0)
          setOpenOrgs(0)
      }).catch((e) => {
          alert('There was an internal error, please try again later')
          setProcessing(0)
      })

  }

  useEffect(() => {
    axios
      .get(urlbase + "/api/check-session", settings())
      .then((res) => {
        if (res.data.message == "Unauthenticated." || res.data.code == 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        sessionStorage.clear();
        window.location.href = "/login";
      });
    setInterval(() => {
      checkToken();
    }, 60000); // every 1 minute -60000
  }, []);

  const [organizations, setOrganizations] = useState([])
  const getOrganizations = () => {
    axios
    .get(urlbase + "/api/org/user", settings())
    .then((res) => {
      if (res.data.code === 200) {
        setOrganizations(res.data.data.length > 0 ? res.data.data : []);
        // console.log(res.data.data.length)
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getNotifications = async () => {
    axios
      .get(urlbase + "/api/notifications", settings())
      .then((res) => {
        if (res.data.code === 200) {
          setNotify(res.data.data.length > 0 ? res.data.data : []);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getOrganizations()
    getNotifications();
  }, []);

  function shortenTextToWidth(text) {
    if (text.length <= 30) {
      return text; 
    } else {
      const ellipsis = '...';
      const remainingWidth = 30  - ellipsis.length;
      return text.slice(0, remainingWidth) + ellipsis;
    }
  }

  const [id, setId]  = useState()
  const [title, setTitle]  = useState()
  const [body, setBody]  = useState()
  const [date, setDate]  = useState()
  function showFullNotification(id,title,body,date){
    setId(id)
    setTitle(title)
    setDate(date)
    setBody(body)
    setNotifications(!notifications)
    setSingleNotifications(!singlenotifications)
  }

  function readNotification(id){
    setSingleNotifications(!singlenotifications)
    axios
    .get(urlbase + "/api/notifications/"+id, settings())
    .then((res) => {
      if (res.data.code === 200) {
        getNotifications();
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function readAll(){
    setNotifications(!notifications)
    axios
    .get(urlbase + "/api/notifications/readall", settings())
    .then((res) => {
      if (res.data.code === 200) {
        getNotifications();
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const filterRef = useRef(null);
  const handleOutsideClick = (event) => {
    setTimeout(() => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setNotifications(false);
      }
    }, 0);
  };
  
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  function openCloseNotification(e){
    e.stopPropagation();
    setSingleNotifications(0)
    setNotifications(!notifications)
  }

  const [openOrg,setOpenOrgs] = useState()
//alert (sessionStorage.setItem('chgamsEntImage'))
  return (
    <>
      {singlenotifications ? (
        <div className="bg-[#FFF] absolute top-14 right-44 w-[500px] shadow-[-12px_8px_50px_0px_rgba(0,_0,_0,_0.08)] rounded-[8px] pb-8">
          <div className="flex justify-between p-4">
            <h1 className="text-[#121927] text-[18px] font-[600] leading-normal">{title}</h1>
            <h1 className="cursor-pointer text-[#828282]" onClick={() => readNotification(id)}>Close</h1>
          </div>
          <div className="p-4 flex flex-row justify-between gap-x-5 border-t-[1px] border-[#EAEAEA] py-3 w-full cursor-pointer">
              <div className="flex flex-row space-x-3">
                <div className="rounded-[50%] bg-blue-500 w-[32px] h-[32px] text-center text-white grid mt-1"><p className="my-auto text-[14px] font-[600]">{title[0]}</p></div>
                <div className="flex flex-col w-[90%]">
                  <span className="text-[12px] text-[#4F4F4F]">{body}</span>
                  <span className="text-[10px] text-[#828282] pt-1">{date}</span>
                </div>
              </div>
          </div>
        </div>
      ) : null}

      {notifications ? (
        <div ref={filterRef} className="bg-[#FFF] absolute top-14 right-44 w-[500px] shadow-[-12px_8px_50px_0px_rgba(0,_0,_0,_0.08)] rounded-[8px] pb-8">
          <div className="flex justify-between p-4">
            <h1 className="text-[#121927] text-[18px] font-[600] leading-normal">Notifications</h1>
            {notify.length > 0 ?<h1 className="text-[#B30000] cursor-pointer" onClick={readAll}>Mark all as read</h1> : <h1 className="text-[#B30000]">No Notifications</h1>}
          </div>
        {notify.map(({id,title,body,is_read,created_at}) => {
          return (
            <div className="p-4 flex flex-row justify-between gap-x-5 border-t-[1px] border-[#EAEAEA] py-3 w-full cursor-pointer" onClick={() => showFullNotification(id,title,body,created_at)}>
              <div className="flex flex-row space-x-3">
                <div className="rounded-[50%] bg-blue-500 w-[32px] h-[32px] text-center text-white grid mt-1"><p className="my-auto text-[14px] font-[600]">{title[0]}</p></div>
                <div className="flex flex-col">
                  <span className="font-[600] text-[12px]">{title}</span>
                  <span className="text-[12px] text-[#4F4F4F]">{shortenTextToWidth(body)}</span>
                  <span className="text-[10px] text-[#828282] pt-1">{created_at}</span>
                </div>
              </div>
              {!is_read ? <span className="w-[8px] h-[8px] rounded-[50%] bg-[#B30000]"></span> : null}
          </div>
          )
        })}

        </div>
      ) : null}
      <div className="flex flex-row justify-between py-2 lg:py-5 px-5 items-center h-[80px] border-b-[1px]">
        <div className="text-[28px] font-[500]">
          <p>
            {sessionStorage.getItem("enterprise")
              ? sessionStorage.getItem("enterprise")
              : "CHGAMS ENTERPRISE"}
          </p>
        </div>
        <div className="flex flex-row items-center space-x-3">
          <div className="col-span-4 text-right lg:hidden">
            <button
              onClick={navAction}
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 outline-none lg:hidden"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {sessionStorage.getItem('role') != 1 ? null :
          <div className="hidden lg:block pr-3 relative">
            <img
              className="rounded-full w-[24px] h-[24px] cursor-pointer"
              src={notification}
              onClick={openCloseNotification}
            />
            <span className="absolute top-[-6px] font-[700] z-[50] text-[#B30000] text-[12px] left-4">{notify.length > 0 ? notify.length  : ''}</span>
          </div>}
          {!processing ?
          <>
          <div className="hidden lg:block">
            <img className="rounded-full w-[40px] h-[40px]" src={(sessionStorage.getItem('chgamsEntImage') == null || sessionStorage.getItem('chgamsEntImage') == 'null')  ?  profile2 : sessionStorage.getItem('chgamsEntImage')} />
          </div>
          <Link to={`/${base}/account`}>
            <div className="hidden lg:block">
              {sessionStorage.getItem("user_name")
                ? sessionStorage.getItem("user_name")
                : "Chgams User"}
            </div>
          </Link>
          {organizations.length > 0 ? <img onClick={() => setOpenOrgs(!openOrg)} className="cursor-pointer" src={orgdrop}/> : null }
          {openOrg ?
        <div className="bg-[#FFF] absolute top-14 right-5 w-[250px] shadow-[-12px_8px_50px_0px_rgba(0,_0,_0,_0.08)] rounded-[8px]">
          <div className="flex flex-row justify-between items-center gap-x-10 p-3 cursor-pointer hover:bg-[#F7F7F7]">
            <div className="flex flex-row space-x-3 items-center">
              <div className="text-center text-white grid mt-1"><img className="rounded-[50%] bg-blue-500 w-[31px] h-[31px]" src={(sessionStorage.getItem('chgamsEntImage') == null || sessionStorage.getItem('chgamsEntImage') == 'null')  ?  profile2 : sessionStorage.getItem('chgamsEntImage')}/></div>
              <span className="text-[12px] text-[#4F4F4F]">{sessionStorage.getItem("enterprise")}</span>
            </div>
            <div className="rounded-[24px] bg-[#EDFFF3] w-[46px] h-[18px] text-center text-[#0D773A] text-[8px] grid"><p className="my-auto">Active</p></div> 
          </div>
          {organizations.map(({id,name,image}) => {
            return (
              <>
                <div className="flex flex-row justify-between items-center gap-x-10 p-3 cursor-pointer hover:bg-[#F7F7F7]" onClick={() => switchOrg(id)}>
                  <div className="flex flex-row space-x-3 items-center">
                    <div className="text-center text-white grid mt-1"><img className="rounded-[50%] bg-blue-500 w-[31px] h-[31px]" src={(image == null || image == 'null') ? profile2 : image}/></div>
                    <span className="text-[12px] text-[#4F4F4F]">{name}</span>
                  </div>
                  <div className="rounded-[24px] bg-[#E9E9E9] w-[46px] h-[18px] text-center text-[#292D32] text-[8px] grid"><p className="my-auto">Inactive</p></div> 
                </div>
                </>
                
            )
            })}

            </div>
             : null}
          </> : <ThreeCircles
                  height="40"
                  width="40"
                  color="#B30000"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                /> }
        </div>
      </div>
    </>
  );
}

const navAction = () => {
  const sidebar = document.getElementById("side");
  sidebar.classList.toggle("-translate-x-full");
};

function Layout({ props }) {
  const DirectPage = () => {
    const pages = [
      { id: "claims", page: <Claims></Claims> },
      { id: "giving", page: <Giving></Giving> },
      { id: "givingdetails", page: <GivingDetails></GivingDetails> },
      { id: "givingtypes", page: <GivingTypes></GivingTypes> },
      { id: "tickets", page: <Tickets></Tickets> },
      { id: "ticketdetails", page: <TicketDetails></TicketDetails> },
      { id: "addgiving", page: <AddGiving></AddGiving> },
      { id: "groups", page: <Groups></Groups> },
      { id: "groupdetails", page: <GroupDetails></GroupDetails> },
      { id: "home", page: <Home></Home> },
      // {id:'support',page:<Support></Support>},
      { id: "members", page: <Members></Members> },
      { id: "exited-members", page: <ExitedMembers></ExitedMembers> },
      { id: "memberdetails", page: <MemberDetails></MemberDetails> },
      { id: "exitedmemberdetails", page: <ExitedMemberDetails></ExitedMemberDetails> },
      { id: "report", page: <Report></Report> },
      { id: "settings", page: <Settings></Settings> },
      { id: "login", page: <Login></Login> },
      { id: "account", page: <Account></Account> },
    ];
    let res = pages.filter((page) => {
      return page.id === props;
    });
    return res[0].page;
  };

  const [draftSaved, setDraftSaved] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("draft") == 1) {
      setDraftSaved(1);
    }
  }, []);

  const cancelAlert = () => {
    sessionStorage.setItem("draft", 0);
    setDraftSaved(0);
  };

  return (
    <>
      {draftSaved ? (
        <div className="flex flex-row justify-between h-[63px] bg-[#121927] items-center px-10 top-0 sticky">
          <span></span>
          <span className="text-white font-[500] text-[18px]">
            Your claim has been saved to your draft
          </span>
          <span
            onClick={cancelAlert}
            className="bg-[#282F3B] rounded-full w-[32px] h-[32px] grid text-white cursor-pointer"
          >
            <p className="my-auto">X</p>
          </span>
        </div>
      ) : null}
      <div className="grid grid-cols-8 relative h-screen overflow-hidden">
        <div
          id="side"
          className="col-span-2 w-[82%] sm:w-[50%] lg:w-[120%] xl:w-[85%] h-screen overflow-y-auto absolute inset-y-0 left-0 transform -translate-x-full xl:relative xl:translate-x-0 transition duration-500 ease-in-out scrollbar-hide z-50 bg-[#F7F7F7]"
        >
          <SideBar></SideBar>
        </div>
        <div className="overflow-y-auto flex flex-col col-span-8 xl:ml-[-5%] xl:col-span-6 scrollbar-hide">
          <div className="mb-4 lg:mb-0 sticky top-0 z-30 w-full bg-white h-[80px]">
            <Header></Header>
          </div>
          <div className="bg-white">
            <DirectPage></DirectPage>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
