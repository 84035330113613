import React, {useState,useEffect} from 'react'
import download from "../../../images/icons/download.svg"
import success from "../../../images/success_green.svg"
import alert from "../../../images/icons/alert.svg"
import { CSVLink, CSVDownload } from "react-csv";
import RunningClaims from './running'
import ClaimHistory from './history';
import ClaimsDraft from './draft';
import {AiOutlineEye,AiOutlineEyeInvisible} from 'react-icons/ai'
import settings from '../../../components/getrequest'
import axios from 'axios'
import { ThreeDots } from 'react-loader-spinner'


function Claims() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const[fetching, setFetching] = useState(0)
  useEffect(()=>{
   if(sessionStorage.getItem('singleHistory') == 1){
     //setClaimsCat(2)
   }
 },[])

  const donationsheaders = [
    { label: "", key: "" },
    { label: "member id", key: "member_id" },
    { label: "total giving", key: "total_giving" },
    { label: "name", key: "name" },
    { label: "email", key: "email" },
    { label: "description", key: "description" },
    { label: "last_donation", key: "last_donation" },
  ]
  const [donationsData, setDonationsData] = useState([

])

useEffect(()=>{
  
  const getClaims = async () => {
    setFetching(1)
  //get running claims
  axios.get(urlbase+'/api/claims',settings())
  .then((res) => {
      if(res.data.code == 200){
          setDonationsData(res.data.data.givings)
      }else{
         console.log(res.data.message)
      }
      setFetching(0)
  }).catch((e) => {
    setFetching(0)
    console.log('There was an internal error, please try again later')
  })
}
getClaims()
allPeriods()

},[])

//claims category
const [claimsCat, setClaimsCat] = useState(sessionStorage.getItem('claimsCat') ? sessionStorage.getItem('claimsCat') : 1)
sessionStorage.removeItem('claimsCat')

//Claim Submission

const [initiate, setInitiate] = useState()
const [claimCreated, setClaimCreated] = useState()
const [summaryModal, setSummaryModal] = useState()
const [acceptTerms, setAcceptTerms] = useState()
const [governInfo, setGovernInfo] = useState()
const [submitted, setSubmitted] = useState()

const [termsAccepted, setTermsAccepted] = useState()

const [claim_draft, setClaimDraft] = useState({type:'draft'})
const AddDraftData = (field,value) => {
  setClaimDraft({...claim_draft, [field]:value})
}
const [addingProcessing,setAddingProcessing] = useState()
function createClaim(){
  // if(!claim_draft.start || !claim_draft.end){
  //   // window.alert('Please fill all fields');
  //   // return
  // }else{
    PerformClaimDraft();
  // }

}

const startProcess = () => {
  if(donationsData.length < 1) {
    window.alert('Oops! You do not have any donations to make claims for in the selected period.')
  }else{
    setInitiate(1)
  }

}

const [claimId, setClaimId] = useState()
const PerformClaimDraft = () => {
  setAddingProcessing(1)
  setAddingProcessing(1)
  axios.post(urlbase+'/api/claims/create',claim_draft,settings())
  .then((res) => {
      if(res.data.code == 200){
        setInitiate(0)
        sessionStorage.setItem('chgamsclaimid',res.data.data.id)
        setClaimCreated(1)
      }else{
          window.alert(res.data.message);
          setInitiate(0)
      }
      setAddingProcessing(0)
  }).catch(() => {
      window.alert('There was an error, please try again');
      setAddingProcessing(0)
  })
}

function setSummary(){
  setClaimCreated(0)
  getSummary()
}

const [claimSummary, setClaimSummary] = useState({})

const getSummary = () => {
  setAddingProcessing(1)
  axios.get(urlbase+'/api/claims/summary/'+sessionStorage.getItem('chgamsclaimid'),settings())
  .then((res) => {
      if(res.data.code == 200){
        setClaimSummary(res.data.data)
        setClaimCreated(0)
        setSummaryModal(1)
      }else{
          window.alert(res.data.message);
          setInitiate(0)
      }
      setAddingProcessing(0)
  }).catch(() => {
      window.alert('There was an error, please try again');
      setAddingProcessing(0)
  })
}

function termsModal(){
  setSummaryModal(0)
  setAcceptTerms(1)
}

function governInfoModal(){
  setAcceptTerms(0)
  setTermsAccepted(0)
  setGovernInfo(1)
}
const [claimDetails, setClaimDetails] = useState({claim_id:claimId,type:'claim'})
function processSubmission(){
  PerformClaim()
}

const PerformClaim = () => {
  setAddingProcessing(1)
  claimDetails.claim_id = sessionStorage.getItem('chgamsclaimid')
  axios.post(urlbase+'/api/claims/create',claimDetails,settings())
  .then((res) => {
      if(res.data.code == 200){
        setGovernInfo(0)
        setInitiate(0)
        setSubmitted(1)
      }else{
          setInitiate(0)
          window.alert(res.data.message);
      }
      setAddingProcessing(0)
  }).catch(() => {
      window.alert('There was an error, please try again');
      setAddingProcessing(0)
  })
}

function cancelProcess(){
  setClaimCreated(0)
  sessionStorage.setItem('draft',1)
  window.location.href='/claims'
}

const [see,setSee] = useState()

const AddClaim = (e,field) => {
  setClaimDetails({...claimDetails, [field]:e.target.value})
}

const accepting = (e) =>{
  if(e.target.checked){
    setTermsAccepted(1)
  }else{
    setTermsAccepted(0)
  }
}

const cancelAccepting = () => {
  setAcceptTerms(0)
  setTermsAccepted(0)
}
 
const [periods, setPeriods] = useState([])
const allPeriods = async () => {
  axios
    .get(urlbase + "/api/settings/periods", settings())
    .then((res) => {
      if (res.data.code === 200) {
        setPeriods(res.data.data)
        console.log(res.data.data)
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const [periodToChange, setPeriodToChange] = useState()
const [processing, setProcessing] = useState()
const changePeriod = async () => {
  if(sessionStorage.getItem('claim_end') == periodToChange.end){
    return;
  }
  setProcessing(1)
  axios
    .post(urlbase + "/api/settings/periods/set",periodToChange, settings())
    .then((res) => {
      if (res.data.code == 200) {
        sessionStorage.setItem('claim_start',periodToChange.end)
        sessionStorage.setItem('claim_end',periodToChange.end)
        window.alert('Period to view changed successfully')
        window.location.reload()
      } else {
        console.log("Error");
        setProcessing(0)
      }
    })
    .catch((error) => {
      console.log(error);
      setProcessing(0)
    });
};

const setThePeriod = (e) => {
  let val = e.target.value
  var start = val.substring(0, 4);
  var end = val.substring(4);
  console.log(val)
  setPeriodToChange({...periodToChange,
    end:end,start:start
  })
}
  return (
    <>

  {initiate ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[3%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                        
                    </h3>
                    <button onClick={() => setInitiate(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col mt-3 mb-10 px-6 text-start space-y-4 items-center'>
                  <img src={success} className="w-[122.5px] h-[122.5px]" />
                  <span className='text-[#121927] font-[700] text-[24px] text-center'>You are about to submit a claim for ({sessionStorage.getItem('claim_start') == sessionStorage.getItem('claim_end') ? sessionStorage.getItem('claim_end') : sessionStorage.getItem('claim_start') +'-'+ sessionStorage.getItem('claim_end') })</span>
                  <span className='text-center'>Check to ensure that your organization's HMRC details are correct in your settings. And confirm you have correctly selected the accounting year you wish to claim for.</span>
                </div>
                {addingProcessing ? 
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      Please wait...
                  </div> :
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setInitiate(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white">Cancel</button>
                      <button onClick={createClaim} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>
                  </div>
                }

            </div>
        </div>
    </div>
    :null}

  {claimCreated ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                        Claim created
                    </h3>
                    <button onClick={() => setClaimCreated(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col mt-3 mb-20 px-6 text-start space-y-4 text-[#4F4F4F] text-[16px]'>
                  <p>The claim has been created and is now listed as draft in your claims lists. </p>
                  <p className=''>This screen walks you through submitting claim to HRMC. You don't have to submit claim now; you may cancel this process and submit at a more convenient time.</p>
                </div>
                <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                    <button onClick={cancelProcess} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white">Cancel</button>
                    {/* <button onClick={termsModal} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white font-[600]`}>Continue</button> */}
                    <button onClick={setSummary} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>
                </div>
            </div>
        </div>
    </div>
    :null} 

{summaryModal ?
  <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
    <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
        <div className="relative bg-white shadow pt-3 rounded-[8px]">
            <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <h3 className="text-[#121927] text-[20px] font-[600]">
                  Repayment Claim Summary
                </h3>
                <button onClick={() => setSummaryModal(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                    <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className='flex flex-col mt-3 mb-20 px-6 text-start text-[#4F4F4F] text-[16px] space-y-3'>
              <div className='flex flex-row justify-between'>
                <span>Individual donations</span>
                <span className='text-[#121927]'>£{claimSummary.individual? claimSummary.individual.toLocaleString(): 0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>Aggregated donations</span>
                <span className='text-[#121927]'>£{claimSummary.aggregated ? claimSummary.aggregated.toLocaleString() : 0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>GASDS</span>
                <span className='text-[#121927]'>£{claimSummary.gasd ? claimSummary.gasd.toLocaleString() :0}</span>
              </div>
              <div className='flex flex-row justify-between'>
                <span>Adjustments</span>
                <span className='text-[#121927]'>£{claimSummary.adjustments ? claimSummary.adjustments.toLocaleString() : 0}</span>
              </div>
            </div>
            <div className='flex flex-col mt-[-35px] mb-20 px-6 text-start text-[#4F4F4F] text-[16px] space-y-3 border-t-[1px] border-b-[1px] py-4'>
              <div className='flex flex-row justify-between'>
                <span>Total to be recieved</span>
                <span className='text-[#121927] text-[20px] font-[600]'>£{claimSummary.tax_reclaimed ? claimSummary.tax_reclaimed.toLocaleString() : 0}</span>
              </div>
            </div>
            <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                <button onClick={() => setSummaryModal(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white font-[600]">Cancel</button>
                <button onClick={termsModal} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white font-[600]`}>Continue</button>
            </div>
        </div>
    </div>
    </div>
    :null} 

  {acceptTerms ?
          <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-6 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                        Submit Repayment Claim to HRMC
                      </h3>
                      <button onClick={cancelAccepting} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col mt-3 mb-20 px-6 text-start space-y-4 text-[#4F4F4F] text-[16px]'>
                    <div className=''>
                      <p className='text-[#121927] font-[600]'>{sessionStorage.getItem('enterprise')}</p>
                      <p className='mt-1'>This is the final step in the claim preparation process. Please indicate your agreement to the statement below.</p>
                    </div>
                    <div>
                      <p className='text-[#121927] font-[600]'>Declaration</p>
                      <p className='mt-1'>The information I have given in this claim is correct to the best of mh knowledge and belief.</p>
                      <p className='mt-1'>I confirm I have read the HRMC notes and guidance on claiming gift Aid and/or top-up payments under the Gift Aid Small Donation Schemes.</p>
                      <p className='mt-1'>Where this claim wholly or partly relates to Gift Aid, I claim exemption from tax for this charity for the period covered by the claim.</p>
                      <p className='mt-1'>Where this claim wholly or partly relates to Gift Aid, I claim exemption from tax for this charity for the period covered by the claim.</p>
                      <span className='mt-1 flex flex-row space-x-2'>
                        <input onChange={(e) => accepting(e)} type='checkbox' className='accent-[#B30000]'/>
                        <p>I understand that false statement can lead to prosecution.</p>
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setAcceptTerms(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white font-[600]">Go back</button>
                      <button onClick={governInfoModal} disabled={!termsAccepted ? true : false} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] ${termsAccepted ? 'bg-[#B30000]': 'bg-[#E0E0E0]'} rounded-[8px] border-none text-white font-[600]`}>Continue</button>
                  </div>
              </div>
          </div>
      </div>
      :null} 

  {governInfo ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center w-full z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[20%] lg:mt-[4%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                        Government Gateway
                    </h3>
                    <button onClick={() => setGovernInfo(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col mt-3 mb-20 px-6 text-start space-y-4 text-[#4F4F4F] text-[16px]'>
                  <div className='flex flex-col space-y-2'>
                    <label>User ID</label>
                    <input className='border-[1px] rounded-[8px] pl-2 h-[50px]' onChange={(e) => AddClaim(e,'username')} placeholder='Input user Id' />
                  </div>
                  <div className='flex flex-col space-y-2'>
                    <label>Password</label>
                    <div className='relative'>
                      <input type={see ? 'text' : 'password'} onChange={(e) => AddClaim(e,'password')} className='relative border-[1px] w-full rounded-[8px] pl-2 h-[50px]' placeholder='Enter password' />
                      <span onClick={() => setSee(!see)} className='absolute right-3 top-4 cursor-pointer'> {see ? <AiOutlineEye/> : <AiOutlineEyeInvisible/> }</span>
                    </div>
                  </div>
                  <div className='border-[1px] rounded-[8px] flex flex-row space-x-3 p-3'>
                    <span className='mt-[-8px]'><img className='w-[64px] h-[64px]' src={alert} /></span>
                    <span className='text-[14px]'>
                      <p>Be sure you enter your <span className='font-[500] text-[#121927'>Government Gateway</span> credentials above, not your Chgams login details! </p>
                      <p className='mt-2'>Please also note the Government Gateway password is <span className='font-[500] text-[#121927'>case sensitive.</span></p>
                    </span>
                  </div>
                </div>
                {addingProcessing ? <div className='justify-end text-right items-center'><p>Please wait</p></div> :
                <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                    <button onClick={() => setGovernInfo(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white">Cancel</button>
                    <button onClick={processSubmission} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>
                </div>
                }
            </div>
        </div>
    </div>
    :null} 

  {submitted ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
                <div className="flex items-start justify-between px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                    </h3>
                    <button onClick={() => setSubmitted(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col px-6 text-start space-y-4 items-center'>
                  <img src={success} className="w-[122.5px] h-[122.5px]" />
                  <span className='text-[#121927] font-[700] text-[24px]'>Claim submitted successfully!</span>
                  <span className='text-center'>Claim successfully submitted. Please check your email for confirmation from HMRC.</span>
                </div>
                <div className="flex flex-row justify-center pb-10 space-x-2 px-8 py-4 rounded-b-[8px]">
                    <a href='/' data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[177px] h-[50px] rounded-[8px] bg-white grid text-center"><p className="my-auto">Go to dashboard</p></a>
                    <a href={'/'+base+'/claims'} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white grid text-center`}><p className="my-auto">See all claims</p></a>
                </div>
            </div>
        </div>
    </div>
    :null}

    {fetching ? 
            <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                wrapperStyle={{}} wrapperClassName="" visible={true}
                />
            </div> 
            :
      <div className={`grid grid-cols-1 mt-5 mb-5 px-8 ${initiate || claimCreated || summaryModal ? 'opacity-25' : ''}`}>
        <div className='col-span-1 flex lg:flex-row flex-col justify-between space-y-4 lg:space-y-0'>
          <p className='text-[#292D32] text-[24px] font-[600] text-start'>Claims</p>
          <div className='flex flex-row space-x-4'>
       
          <>
          <CSVLink filename={"runningClaims.csv"} data={donationsData} headers={donationsheaders}>
            <div className='border-[1px]  w-[165px] h-[40px] px-3 cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={download} />
              <p className='text-[#292D32] text-[15px] font-[600]'>Download CSV</p>
            </div>
            </CSVLink>
            <div onClick={startProcess} className=''><button className={`p-2 h-[40px] w-[195px] rounded-[4px] text-white bg-[#B30000]`}>Submit claim to HRMC</button></div>
            </> 
          </div>
        </div>
        <div className='col-span-1 border-b-[1px] mt-8 flex flex-row space-x-8'>
            <span onClick={() => setClaimsCat(1)} className={`${claimsCat == 1 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''}  px-2 pb-2 cursor-pointer`}>Running</span>
            <span onClick={() => setClaimsCat(2)} className={`${claimsCat == 2 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-2 cursor-pointer`}>History</span>
            <span onClick={() => setClaimsCat(3)} className={`${claimsCat == 3 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-2 cursor-pointer`}>Draft</span>
        </div>
        <div className='grid grid-cols-1'>
            {claimsCat == 1 ? <RunningClaims></RunningClaims> : claimsCat == 2 ? <ClaimHistory></ClaimHistory> : <ClaimsDraft></ClaimsDraft>}
        </div>
      </div> }


    </>
  )
}

export default Claims