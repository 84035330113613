import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

function Doc() {

 let menu = [
    {'id':1,'title':'Rules'},
    {'id':2,'title':'Content'},
    {'id':3,'title':'Plans and Pricing'},
    {'id':4,'title':'Web App Restrictions'},
  ];
  const [activeMenu, setActiveMenu] = useState('Rules');
  const switchActive = (id,title) => {
    document.documentElement.style.scrollBehavior = "smooth";
    setActiveMenu(title)
    const element = document.getElementById(title);
    element.scrollIntoView();
  }

  const handleScroll = () => {
    // Logic to identify the active section based on scroll position
    const sectionElements = document.querySelectorAll('.documentation-section');
    
    sectionElements.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
        // Section is approximately in the middle of the viewport
        const sectionId = section.getAttribute('id');
        setActiveMenu(sectionId);
        if (sectionId !== activeMenu) {
          setActiveMenu(sectionId);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

  return (
    <div className='grid grid-cols-8 px-5 lg:px-20 lg:pt-10 pb-20 '>
      <div className='col-span-2 hidden lg:block sticky top-0 w-full h-screen overflow-y-auto absolute'>
        {/* <span className='text-[#333333] text-[18px] font-[600]'>CHGAMS DOCUMENTATION</span> */}
        <div className='flex flex-row mt-8'>
          {/* <div className='w-[1px] bg-[#A5A5A5]'></div> */}
          <div className='flex flex-col'>
          {menu.map(({id,title}) => {
            return(
              <div className='flex flex-row space-x-5 cursor-pointer' onClick={() => switchActive(id,title)}>
                  <div key={id} className={`w-[1px] ml-[-1px] ${activeMenu == title ? 'bg-[#B30000]' : null}`}></div>
                  <div className={`${activeMenu == title ? 'text-[#B30000]' : 'text-[#A5A5A5]'} text-[14px] ${title != 'Introduction' ? 'pt-4' : null} ${title == 'Conclusion' ? 'pb-10 mb-5' : null}`}>{title}</div>
                </div>
            )
          })}
          </div>
        </div>
      </div>
      <div className='col-span-8 lg:col-span-6 flex flex-col space-y-8'>
        <div className='font-[500] text-[40px]'>Terms and Conditions</div>
          <p className='text-[16px] text-[#4F4F4F] font-[300]'>This agreement is between CHGAMS LTD and you. Prior to using the App, we kindly ask you to carefully read these Terms. We aim for you to fully understand both your rights and ours before utilizing the CHGAMS Web Application. These Terms become binding upon you upon accessing, viewing, or using the App.</p>

          <div className='flex flex-col documentation-section' id="Rules">
            <div className='text-[#B30000] font-[600] text-[20px]'>Rules</div>
            <p className='text-[16px] text-[#4F4F4F] font-[300]'>Before accessing the app, you are required to register for an account ("Account"). To create an account, you must:</p>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              <ul className='list-disc ml-5 space-y-2'>
                <li>
                  <p>Be of legal age to enter into contracts according to the laws of your country, which is typically at least 21 years old or the age of majority.</p>
                </li>
                <li>
                  <p>Be permitted by the laws of your local country to utilize the App.</p>
                </li>
                <li>
                  <p>Have established an account with HMRC.</p>
                </li>
              </ul>
            </div>
            <p className='text-[16px] text-[#4F4F4F] font-[300] pt-4'>We employ a combination of automated technology and administrative staff to monitor accounts and communications for any content indicating violations of these Terms. We reserve the right, without prior notice or liability, to terminate or suspend any Account, limit access to the App, or employ any operational, technological, legal, or other means available to enforce the Terms (including, but not limited to, blocking specific IP addresses).</p>
          </div>

          <div className='flex flex-col documentation-section' id="Content">
            <div className='text-[#B30000] font-[600] text-[20px]'>Content</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2'>
            <p className='font-[400] text-[#333333]'>You agree that Your Content must comply with the following guidelines:</p>
            <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p>Your content is solely your responsibility, and you agree to indemnify, defend, release, and hold CHGAMS LTD harmless against any claims arising from it.</p>
                </li>
                <li className='space-y-1'>
                  <p>By submitting content to CHGAMS, you grant us permission to process and submit your claim to HMRC for your gift Aid claim.</p>
                </li>
                <li className='space-y-1'>
                  <p>By submitting your gift aid claims to HMRC through CHGAMS, you agree to hold evidence of data shared sent to HMRC for verification should the need arise from HMRC</p>
                </li>
                <li className='space-y-1'>
                  <p>CHGAMS has no idea or visibility of data being sent to HMRC for gift aid claims</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Plans and Pricing">
            <div className='text-[#B30000] font-[600] text-[20px]'>Plan and Pricing</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2'>
            <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p>You will enjoy a complimentary one-week trial on our platform. If you do not subscribe within this trial period, your data will be deleted.</p>
                </li>
                <li className='space-y-1'>
                  <p>Please note that our premium features require a minimum subscription period of one year, and cancellation is not permitted until the full payment for the chosen plan is completed. This means that even if you opt for monthly payments, your subscription is still bound to a one-year commitment, and cancellation is only possible after the year has elapsed.</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Web App Restrictions">
            <div className='text-[#B30000] font-[600] text-[20px]'>Web App Restrictions</div>
            <p className='mt-4'>You agree to</p>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p>Adhere to all applicable laws, including those related to equal opportunity, privacy, intellectual property, anti-spam, and equal opportunity.</p>
                </li>
                <li className='space-y-1'>
                  <p>Provide your true name and age when creating your CHGAMS account and updating your profile.</p>
                </li>
                <li className='space-y-1'>
                  <p>Utilize the services in a professional manner.</p>
                </li>
              </ul>
            </div>
            <p className='mt-4'>You agree to not</p>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p>Falsify your age, identity, credentials, employment history, or affiliations with individuals or organizations.</p>
                </li>
                <li className='space-y-1'>
                  <p>Create, maintain, or employ software, hardware, robots, scripts, or any other means (such as browser plugins, add-ons, or other technologies) to scrape, extract, or duplicate profiles and other data from CHGAMS or its services.</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Conclusion">
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              By using the App, you hereby release CHGAMS LTD and its successors from any and all claims, demands, losses, damages, rights, and actions, including but not limited to death, bodily harm, and property damage, arising directly or indirectly from your use of the App or interactions with it.
            </div>
          </div>
      </div>
    </div>
  )
}

export default Doc